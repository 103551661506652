import React from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap'
import '../assets/sass/components/Header.scss'

const Header = (props) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div>
      <Navbar expand='md' className={isOpen === true ? 'toggle-btn-open' : ''}>
        <div className='container'>
          <NavbarBrand onClick={() => navigate('/')} style={isOpen ? { visibility: 'hidden' } : {}}>
            Marvell Properties
          </NavbarBrand>
          <NavbarToggler
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className='ml-auto' navbar>
              <NavItem className={props?.isActive === 'home' ? 'active' : ''}>
                <NavLink to='/'>Home</NavLink>
              </NavItem>
              <NavItem className={props?.isActive === 'games' ? 'active' : ''}>
                <NavLink to='/games'>Projects</NavLink>
              </NavItem>
              <NavItem className={props?.isActive === 'contactUs' ? 'active' : ''}>
                <NavLink to='/contact'>Contact Us</NavLink>
              </NavItem>
              <NavItem className={props?.isActive === 'AboutUs' ? 'active' : ''}>
                <NavLink to='/about'>About Us</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </div>
  )
}

export default Header
