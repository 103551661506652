import React, { useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import OuterProjectCard from '../../components/OuterProjectCard'
import { indexPropertyData, outerProjectCardData } from '../../assets/constatnt'
import InnerPropertyCard from '../../components/InnerPropertyCard'

const Games = () => {
  const [innerData, setInnerData] = useState()
  const [show, setShow] = useState(false)

  const handleIndex = (index) => {
    setInnerData(indexPropertyData[index])
    setShow(!show)
  }

  const handleClose = () => {
    setShow(!show)
  }
  return (
    <>
      <Header isActive='games' />
      <div className='project-sec'>
        <div className='container'>
          <div className='text-center'>
            <h2 className='common-title red'>PROJECTS</h2>
          </div>
          <div className='project-container'>
            {outerProjectCardData?.map((data, index) => {
              return <OuterProjectCard data={data} index={index} handleIndex={handleIndex} />
            })}
          </div>
        </div>
      </div>
      <InnerPropertyCard data={innerData} isOpen={show} handleClose={handleClose} />
      <Footer />
    </>
  )
}
export default Games
