export const outerProjectCardData = [
  {
    cardImage: 'https://lh3.googleusercontent.com/p/AF1QipNJXLHb0WozAbdTCy7ECsbcmdG71HP9i_ScrfLl=s680-w680-h510',
    price: '',
    propertyTitle: 'Shaligram Prime',
    propertyLocation: 'South Bopal',
    propertySize: '1250 sqft to 1610 sqft',
    propertyPriceRooms: '2 BHK | 3 BHK'
  },
  {
    cardImage: 'https://lh3.googleusercontent.com/p/AF1QipP-t69F4BEMPNC2m-GbDPJQ_RZultwl2RKukiWv=s680-w680-h510',
    price: '68,00,000',
    propertyTitle: 'Shilp Revanta',
    propertyLocation: 'club 07 road at Shela',
    propertySize: '1510 SQFT',
    propertyPriceRooms: '3 BHK'
  },
  {
    cardImage: 'https://lh3.googleusercontent.com/p/AF1QipMbTX2FOUzT26hcR1_VQHQUOFceZQNQL-KJ3EPJ=s680-w680-h510',
    price: 'Price 13,500++',
    propertyTitle: 'Navratna Corporate Park',
    propertyLocation: 'Ambli Road',
    propertySize: '525 Carpet to 1500 Carpet',
    propertyPriceRooms: 'Commercial project'
  },
  {
    cardImage: 'https://lh3.googleusercontent.com/p/AF1QipN6WOOvD1vWS9YJyRfMa55fu-DO-mqfRc5hGB54=s680-w680-h510',
    price: 'Price 8,900++',
    propertyTitle: 'Palak Prime',
    propertyLocation: 'Ambli Road',
    propertySize: '900 sqft to 3000 sqft',
    propertyPriceRooms: 'Commercial project'
  },
  {
    cardImage: 'https://lh3.googleusercontent.com/p/AF1QipNNW1gG5Sj0JcltWKnPBFvcvDstcSROGH90eadd=s1360-w1360-h1020',
    price: '8 Cr',
    propertyTitle: 'ARAVALLI',
    propertyLocation: 'Shela',
    propertySize: 'Plot area --- sq.yard | Construction area --- sq.yard',
    propertyPriceRooms: '4 & 5 Bhk'
  },
  {
    cardImage: 'https://www.kavishacorp.com/img/canvas-banner.jpg',
    price: '1 Cr',
    propertyTitle: 'The Canvas by Kavisha',
    propertyLocation: 'Wapa',
    propertySize: '2200 sqft',
    propertyPriceRooms: '3 & 4 & 5 Bhk'
  },
  {
    cardImage: 'https://lh3.googleusercontent.com/p/AF1QipOqbCW5y5_QF-DzoTmVZoZB2-yNHbF95OdbusHK=s680-w680-h510',
    price: '2 BHK 47 Lac  |  1280 sqft 3 BHK 64.75 Lac',
    propertyTitle: 'Mahadev Lavish',
    propertyLocation: 'South Bopal',
    propertySize: '2 BHK 1280 sqft |3 BHK 1750 sqft',
    propertyPriceRooms: '2 & 3 Bhk'
  },
  {
    cardImage: 'https://i.ibb.co/zSNWDsb/photo-2023-06-28-22-52-44.jpg',
    price: 'Price 1.60++ Cr',
    propertyTitle: 'Siddheshwar Bungalows',
    propertyLocation: 'Opp Club O7, Shela',
    propertySize: 'Plot area 209 sq.yard | Construction area 275 sq.yard',
    propertyPriceRooms: '4 BHK'
  },
  {
    cardImage:
      'https://www.mahadevelegance.com/mahadev-elegance/gallery/thumb/09-mahadev-elegance-2-3-bhk-apartments-showroom-sp-ring-road-ahmedabad.jpg',
    price: '21000',
    propertyTitle: 'Mahadev elegance',
    propertyLocation: 'Shela',
    propertySize: '1480 sqft',
    propertyPriceRooms: '3 BHK'
  },
  {
    cardImage: 'https://newprojects.99acres.com/projects/shivalik_projects/shivalik_satyamev/images/piy98awb.jpg',
    price: '31000',
    propertyTitle: 'Shivalik Satyamev',
    propertyLocation: 'sp ring road, bopal',
    propertySize: '826 SQFT',
    propertyPriceRooms: 'Commercial project'
  },
  {
    cardImage:
      'https://img.staticmb.com/mbimages/project/Photo_h240_w0/2020/08/13/Project-Photo-1-The-Retail-Park-Ahmedabad-5119311_345_1366_240_0.jpg',
    price: '20000',
    propertyTitle: 'The Retail Park(TRP)',
    propertyLocation: 'Bopal main road',
    propertySize: '775 sqft',
    propertyPriceRooms: 'Commercial project'
  }
]

export const indexPropertyData = [
  {
    price: '',
    propertyTitle: 'Shaligram Prime',
    propertyLocation: 'South Bopal',
    keyPoints: [
      '2BHK 1250 sqft (62Lacs) 1315 Sq. Ft.',
      '3BHK (63 Lacs) | 3BHK 1435 sqft (72.50 Lacs)',
      '3BHK 1555 sqft (75 Lacs) | 3BHK 1615 sqft (78.50 Lacs)',
      '3 Side Open View',
      'Road Touch Project',
      'A World of Amenities for the Ever-Changing Times',
      'Allotted Car parking',
      'Possession June 2023',
      'For Best Price & more Details Contact Us.'
    ],
    mediaGallery: [
      'https://lh3.googleusercontent.com/p/AF1QipNJXLHb0WozAbdTCy7ECsbcmdG71HP9i_ScrfLl=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipNaU6ZFgEVoxBH21gcCYmK9jWnAr1jCK8owRdjz=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipNB6LI1Z-gf0O9iXZtWDULjuqm7W0bLPAsgJXkw=s680-w680-h510'
    ]
  },
  {
    price: '68,00,000',
    propertyTitle: 'Shilp Revanta',
    propertyLocation: 'club 07 road at Shela',
    keyPoints: [
      'Passion December 2023',
      '3Bhk - 1510 SQFT',
      'Prime location at the Centre of Shela',
      '70+ Amenities',
      'Allotted Car parking',
      'Possession With in 3 Years',
      'For Best Price & more Details Contact Us.'
    ],
    mediaGallery: [
      'https://lh3.googleusercontent.com/p/AF1QipP-t69F4BEMPNC2m-GbDPJQ_RZultwl2RKukiWv=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipPAvEZNjzoqacFb66-auyzAc2JO5KpmULs3liw=s680-w680-h510'
    ]
  },
  {
    price: 'Price 13,500++',
    propertyTitle: 'Navratna Corporate Park',
    propertyLocation: 'Ambli Road',
    keyPoints: [
      `Standing proudly at Ambli-Bopal BRTS Corridor, Ahmedabad's skyline will soon be identified through Navratna Corporate Park.`,
      'A location that needs no directions. A design that needs no introduction. Navratna Corporate Park is set to become an enviable corporate landscape that will need no introduction.',
      'With two approach road, easily-accessible location and influential design, this space will truly define modern corporate culture in Ahmedabad.',
      'For Best Price & more Details Contact Us.'
    ],
    mediaGallery: [
      'https://lh3.googleusercontent.com/p/AF1QipMTV0tPYeW4HoYKZ-JBQhdsgso-2qKtG68xWB6S=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipN5oFhpHELl0srKeehGW8sBopUNy4MtYGGsBCgy=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipMbTX2FOUzT26hcR1_VQHQUOFceZQNQL-KJ3EPJ=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipO6cOch801a2kmef3Ih7MBPjjgjOxSaK-Xfr5GI=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipO9R7bezEkyYxFetVeqBJ6j52uprjlxNFqwkQx-=s680-w680-h510'
    ]
  },
  {
    price: 'Price 8,900++',
    propertyTitle: 'Palak Prime',
    propertyLocation: 'Ambli Road',
    keyPoints: [
      'Ready to move',
      'Palak Prime is a meticulously designed project of Ahmedabad.',
      'It covers an area of 2186 Sq-m giving enough green space to residents. A world class Commercial project, it is among the finest properties available around.',
      ' This skillfully crafted project has Under Construction units. Commercial Showroom, Commercial Office Space are the various types of units available, each of which has been created to provide total satisfaction.',
      'This mesmerizing property is available in sizes from Commercial Showroom (1395 Sqft - 5000 Sqft ), Commercial Office Space (1200 - 3000 Sqft ). The design of the project is such that it is classified into 1 towers',
      'For Best Price & more Details Contact Us.'
    ],
    mediaGallery: [
      'https://lh3.googleusercontent.com/p/AF1QipMMgT3imzdUeaGjl681Xa3XxZC_XI0oNeXk9rsL=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipNOl4MxjidXyXRWoKdnw9EE1k7SMtkQPdB7GGfl=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipM_Gj0U4AyaBHp5eASpw6F5IpPwwKYzLnAcFO2K=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipPwOZtRd8ii7ZNDlDzvLixlwwjDcK0dHOBl0TKp=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipPYTtl1C5nEn1olqldky0GXP44_XaWctV5Szrmj=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipMNg6iHXSHfpWmHQKQAkP9zl8hVLKYAAShF9WnD=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipN6WOOvD1vWS9YJyRfMa55fu-DO-mqfRc5hGB54=s680-w680-h510'
    ]
  },
  {
    price: '8 Cr',
    propertyTitle: 'ARAVALLI',
    propertyLocation: 'Shela',
    keyPoints: [
      'Ready to move',
      '83 bungalows. Peak of luxury.',
      'Look around your dream home and dwell in a state of indulgence, of your mind.',
      'Aravalli is a property for sale in Ahmedabad that comes in three distinct styles of luxurious 4 and 5BHK bungalows – Eka, Aaroha, and Vilasa, each designed to create a feeling of luxury that goes beyond tangible things.',
      'Luxurious bungalows designed for those who know to indulge in the things that really matter.',
      'For Best Price & more Details Contact Us.'
    ],
    mediaGallery: [
      'https://lh3.googleusercontent.com/p/AF1QipNNW1gG5Sj0JcltWKnPBFvcvDstcSROGH90eadd=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipP0Mif5vNhBmjZ6MjQ2l0Y6nTR3fjMP4xR4h_HR=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipMqukPkkv_g0iY-84Xabgsc2us6bgEvsCZ5729m=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipOoaNnPRz8CcVpzsEnW6R9ro_5-8zC7D6Jda3Lw=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipOz1aIWRFTelxHKmDuWMb8Sl2KF_HEZ6RDODohG=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipPakp2KqVNmPGPkZWo17x6aSjWusyh6Dnqlt8Bz=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipPbDWG308xXoY3OfWumA-76x2FzRQOKV-Y9zim6=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipOe75Dpcvnbxvz5L-HTT-gkJ3l6UhmWhCht9XRX=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipOoTo59Td4rKsT47z4Mu9qahiI2hZShZf-a5kRw=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipOkPhiJ2O7WCfu4Zl_p34g_GKMZir_QKHIGp28H=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipPn-SYl0z7Sgu3wBN9Suo9izAJ9w44lYWqgsbhQ=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipMGVW9Zsrh473U_xXEKVBYkAcfC707L9t9txYdN=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipOZKroWaAKAR_2aAT7nmh99rd0whLXpyRbv5Oz-=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipO5kKX7Uwc-H-QmW7W9XrS9CbaSojCDJLP4wFQj=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipNDvxLGsr2WNrbqy3hjVxJgoDoKRift_EFmf_y1=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipO1BCNKfqyJJfBgeHdhTWkbkY-QJ58u6zwQ8taA=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipM437Yyk4wp8DS4l9yl7RFTH4QA0cEUxnda20nK=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipNpNqknU-oOMiA01oyxRpvQqnlJwb07da_qnFnV=s1360-w1360-h1020',
      'https://lh3.googleusercontent.com/p/AF1QipPgwuwedycE5deb1MpaZA1GN4qxExSb6xWkiZor=s1360-w1360-h1020'
    ]
  },
  {
    price: '1 Cr',
    propertyTitle: 'The Canvas by Kavisha',
    propertyLocation: 'Wapa',
    keyPoints: [
      'Passion time December 2024',
      'The homes at The Canvas are designed to offer a spacious and comfortable living experience',
      'With options of 3, 4, and 5 BHK homes, each bedroom is designed to offer ample space and comfort.',
      'The project promises a colourful and vibrant life to its residents, making it the perfect place to call home.',
      'For Best Price & more Details Contact Us.'
    ],
    mediaGallery: [
      'https://www.kavishacorp.com/img/canvas-banner.jpg',
      'https://www.kavishacorp.com/img/the-canvas/s9.jpg',
      'https://www.kavishacorp.com/img/the-canvas/s8.jpg',
      'https://www.kavishacorp.com/img/the-canvas/s7.jpg',
      'https://www.kavishacorp.com/img/the-canvas/s6.jpg',
      'https://www.kavishacorp.com/img/the-canvas/s4.jpg',
      'https://www.kavishacorp.com/img/the-canvas/s3.jpg',
      'https://www.kavishacorp.com/img/the-canvas/s1.jpg'
    ]
  },
  {
    price: '2 BHK 47 Lac  |  1280 sqft 3 BHK 64.75 Lac',
    propertyTitle: 'Mahadev Lavish',
    propertyLocation: 'South Bopal',
    keyPoints: ['Passion time Dec 25th, 2025', 'For Best Price & more Details Contact Us.'],
    mediaGallery: [
      'https://lh3.googleusercontent.com/p/AF1QipOqbCW5y5_QF-DzoTmVZoZB2-yNHbF95OdbusHK=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipOBuxdBZeV2MuZZXcg_Ny9J98fn0GVgAWlWGrYA=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipNYaZlQX05e30dNqR-EDSwsmxlESIABVv_4T7If=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipN-NcmKErAdmr8lgafW1lz23hgZuI_yRBUhuH_r=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipNJtsEJBIfvDT56Y2kyMPbE4xuqXeER9oq8iQEf=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipO9f9EYv668eukZJkQVyH0YSsxScWFcVmZbinRc=s680-w680-h510'
    ]
  },
  {
    price: 'Price 1.60++ Cr',
    propertyTitle: 'Siddheshwar Bungalows',
    propertyLocation: 'Opp Club O7, Shela',
    keyPoints: ['Ready Passion', 'For Best Price & more Details Contact Us.'],
    mediaGallery: [
      'https://i.ibb.co/zSNWDsb/photo-2023-06-28-22-52-44.jpg',
      'https://i.ibb.co/WF3nMqV/photo-2023-06-28-22-52-58.jpg',
      'https://i.ibb.co/WK6Qty0/photo-2023-06-28-22-53-05.jpg',
      'https://i.ibb.co/GC5s87h/photo-2023-06-28-22-53-09.jpg',
      'https://i.ibb.co/nbH5yPX/photo-2023-06-28-22-53-12.jpg',
      'https://i.ibb.co/k6M6nfz/photo-2023-06-28-22-53-15.jpg'
    ]
  },
  {
    price: '21000',
    propertyTitle: 'Mahadev elegance',
    propertyLocation: 'Shela',
    keyPoints: [
      'Mahadev Elegance is 2 & 3 BHK apartments consist premium living & showroom space amalgamation of extravagance',
      'solace and style mix to give away really modern outlook towards life wonderfully crafted bearing in mind all your needs',
      'Mahadev Elegance has been designed to nuture a progressive and socially active neighborhood without compromising on your lifestyle and required amenities.'
    ],
    mediaGallery: [
      'https://lh3.googleusercontent.com/p/AF1QipOYZtCx7cE7lk5gq0dyib1R7KMYxJ4ktp20BDi0=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipNrlD-ot-F9P_lRtJDkr_wMmwiPemJROp5s5bAO=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipMalIcfMMuI9tCbCzQJIlcYkyUurUjih5va37Tf=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipOXNId8FdLJNi8MeIo1FPqoXARunayoad9K2FyW=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipPeWzIM2SBEL1WYjhLHrbrYmrYD0YwxX9UsFM-F=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipN5sYUFfmudBt-lPfxperSSVlTiwP33vgV8mKxM=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipOhEmmsCpmvbnmXM6nSSFDFjVB1KflDfiUbFQx_=s680-w680-h510'
    ]
  },
  {
    price: '31000',
    propertyTitle: 'Shivalik Satyamev',
    propertyLocation: 'sp ring road, bopal',
    mediaGallery: [
      'https://lh3.googleusercontent.com/p/AF1QipMow2AKy3tuGjtAgP6cPn4XFTXCPQcRaoZ5oXc9=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipPqEwlwkQ3SVGNqcW5ExZLs4r2d9b9W7RyDGb_T=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipNzwzSmoCbJdINod1-lqzlEUoD8NfesVkAxBAoM=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipP9DnJTnwKuYoy1VSFHcHTpLNCyLQhY77V5cW-k=s680-w680-h510'
    ],
    keyPoints: [
      'Unfurnished Office Available in Prime Location, at good rate & Best deal The property is very well maintained with all the amenities available.',
      'The space can be used any Business or any Pvt. Ltd. company OR Indivisial Business.',
      'We are the pioneer consultants in Commercial Rent / Lease Property having hundreds of property in commercial.',
      'Please contact us for any commercial property related inquiry.'
    ]
  },
  {
    price: '20000',
    propertyTitle: 'The Retail Park(TRP)',
    propertyLocation: 'Bopal main road',
    keyPoints: [
      'Enjoy a never before shopping experience at The Retail Park',
      'You will get all of your favorite brands in one place Spot the best offers and discounts from the biggest brands for clothing, footwear, electronics, gadgets, sports, decor and more',
      'Come and shop from The Retail Park, an iconic shopping destination for all your need.'
    ],
    mediaGallery: [
      'https://lh3.googleusercontent.com/p/AF1QipMtiEed6r_u1qSENfs9fbC501HvgcOhBqVh41Bx=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipOF0do26aQfPJ6MK5VqM5ftacYiP9dY6kH1MmmE=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipOXN7bu3ww3V0wEXXbDVHmEzXykmOc_geDLmS4m=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipPZnjvOnCYv6cibXZsvJuR9XK2f1a4_0c83X3ow=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipPOiGRM-nZQtvT5t-b4tAacaUYO23rtMwSvStCT=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipN6P7C42gKOHrQuH9HMrWiD8LOMAGbnzjnEzoCK=s680-w680-h510',
      'https://lh3.googleusercontent.com/p/AF1QipN7AIY4reADhGWa8kWo3AYde0-UdD032OqBBqxk=s680-w680-h510'
    ]
  }
]
