import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../views/Home/Home'
import About from '../views/About/About'
import Games from '../views/Games/Games'
import Spinner from '../components/Spinner'
import QueryTable from '../views/QueryTable'
import ContactTable from '../views/ContactTabel/ContactTabel'
const Contact = React.lazy(() => import('../views/Contact/Contact'))

const Routers = () => {
  return (
    <Routes>
      <Route path='/'>
        <Route index element={<Home />} />
      </Route>
      <Route
        path='contact'
        element={
          <Suspense fallback={<Spinner />}>
            <Contact />
          </Suspense>
        }
      />
      <Route path='about' element={<About />} />
      <Route path='games' element={<Games />} />
      <Route path='table/8083535' element={<QueryTable />} />
      <Route path='contact-table/8083535' element={<ContactTable />} />
    </Routes>
  )
}

export default Routers
