import React, { useState } from 'react'
import { deleteQuery, getTable } from '../../query/contact-us/contact.mutation'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Spinner } from 'reactstrap'

function QueryTable() {
  const [tableData, setTableData] = useState()
  const query = useQueryClient()

  const { isFetching } = useQuery('getTable', () => getTable(), {
    select: (data) => data?.data?.data,
    onSuccess: (data) => {
      setTableData(data)
    }
  })

  const handleDelete = (id) => {
    mutate({ id })
  }

  const { isLoading: deleting, mutate } = useMutation(deleteQuery, {
    onSuccess: (data) => {
      query.invalidateQueries('getTable')
    }
  })

  return (
    <section className='m-5'>
      <table class='table table-hover'>
        <thead>
          <tr>
            <th scope='col'>No</th>
            <th scope='col'>Name</th>
            <th scope='col'>Email</th>
            <th scope='col'>Mobile No.</th>
            <th scope='col'>Buy/Rent/Sell</th>
            <th scope='col'>Location</th>
            <th scope='col'>Property Type</th>
            <th scope='col'>Minimum Budget</th>
            <th scope='col'>Maximum Budget</th>
            <th scope='col'>BHK</th>
            <th scope='col'>Square Feet</th>
            <th scope='col'>Delete</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <Spinner />
          ) : (
            tableData?.map((data, index) => {
              return (
                <tr>
                  <th scope='row'>{index + 1}</th>
                  <td>{data?.username || '-'}</td>
                  <td>{data?.email || '-'}</td>
                  <td>{data?.mobile || '-'}</td>
                  <td>{data?.buyorrent || '-'}</td>
                  <td>{data?.location || '-'}</td>
                  <td>{data?.propertyType || '-'}</td>
                  <td>{data?.minBudget || '-'}</td>
                  <td>{data?.maxBudget || '-'}</td>
                  <td>{data?.roomBHK || '-'}</td>
                  <td>{data?.squareFeet || '-'}</td>
                  <td>
                    <button type='button' class='btn btn-primary btn-sm' onClick={() => handleDelete(data?._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              )
            })
          )}
        </tbody>
      </table>
    </section>
  )
}

export default QueryTable
