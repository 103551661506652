import axios from '../../axios'

export async function submitContactUs(data) {
  return await axios.post('/contact', data)
}

export async function getTable(data) {
  return await axios.get('/get/property', data)
}
export async function getContactTable(data) {
  return await axios.get('/get/contact', data)
}

export async function submitData(data) {
  return await axios.post('/property', data?.formData)
}

export async function deleteQuery(id) {
  return await axios.delete('/delete/property/' + id?.id)
}

export async function deleteContactQuery(id) {
  return await axios.delete('/delete/contact/' + id?.id)
}
