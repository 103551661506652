import React from 'react'
import Slider from 'react-slick'
import { Modal, ModalBody } from 'reactstrap'
import CloseButton from '../assets/images/close-icon.png'

function InnerPropertyCard({ data, isOpen, handleClose }) {
  const gameinfoslider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <Modal toggle={handleClose} className='gameinfo-popup' isOpen={isOpen}>
      <ModalBody>
        <>
          <div className='common-modal-sec'>
            <button onClick={handleClose} className='common-close-btn'>
              <img src={CloseButton} alt='' />
            </button>
            <h2>{data?.propertyTitle}</h2>
            <div className='game-info-sec'>
              <div className='game-info-left-sec'>
                <ul>
                  <li>
                    <label>
                      <b>Price:</b>
                      <label>{data?.price}</label>
                    </label>
                  </li>
                  <li>
                    <label>
                      <b>Location: </b>
                      {data?.propertyLocation}
                    </label>
                  </li>
                  <li>
                    <label style={{ color: 'blue' }}>
                      <a href='/contact'>Contact for more details</a>
                    </label>
                  </li>
                </ul>
              </div>
              <div className='game-info-right-sec'>
                <div className='game-info-desc'>
                  <ul>
                    {data?.keyPoints?.map((data) => {
                      return (
                        <li>
                          <label>{data}</label>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className='game-info-slider-sec'>
              <h4>Media Gallery</h4>
              <Slider {...gameinfoslider} className='game-info-slider'>
                {data?.mediaGallery?.map((media, index) => {
                  return (
                    <div key={index}>
                      <div className='gameinfoslide'>
                        <div
                          className='game-info-img'
                          style={{
                            background: 'url(' + media + ')no-repeat center center/cover'
                          }}
                        ></div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </>
      </ModalBody>
    </Modal>
  )
}

export default InnerPropertyCard
