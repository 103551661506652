import React from 'react'
// import loader from '../assets/images/loader.gif'

function Spinner() {
  return (
    <div className='loader'>
      <div class='spinner-border' role='status'>
        <span class='sr-only'>Loading...</span>
      </div>
    </div>
  )
}

export default Spinner
