import React, { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Spinner } from 'reactstrap'
import { deleteContactQuery, getContactTable } from '../../query/contact-us/contact.mutation'

function ContactTable() {
  const [tableData, setTableData] = useState()
  const query = useQueryClient()

  const { isFetching } = useQuery('getContactTable', () => getContactTable(), {
    select: (data) => data?.data?.data,
    onSuccess: (data) => {
      setTableData(data)
    }
  })

  const handleDelete = (id) => {
    mutate({ id })
  }

  const { isLoading: deleting, mutate } = useMutation(deleteContactQuery, {
    onSuccess: (data) => {
      query.invalidateQueries('getContactTable')
    }
  })

  return (
    <section className='m-5'>
      <table class='table table-hover'>
        <thead>
          <tr>
            <th scope='col'>No</th>
            <th scope='col'>Subject</th>
            <th scope='col'>Email</th>
            <th scope='col'>Mobile No.</th>
            <th scope='col'>Message</th>
            <th scope='col'>Delete</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <Spinner />
          ) : (
            tableData?.map((data, index) => {
              return (
                <tr>
                  <th scope='row'>{index + 1}</th>
                  <td>{data?.subject || '-'}</td>
                  <td>{data?.email || '-'}</td>
                  <td>{data?.phonenumber || '-'}</td>
                  <td>{data?.message || '-'}</td>
                  <td>
                    <button type='button' class='btn btn-primary btn-sm' onClick={() => handleDelete(data?._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              )
            })
          )}
        </tbody>
      </table>
    </section>
  )
}

export default ContactTable
