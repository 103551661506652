import React, { useState } from 'react'
import '../assets/sass/components/Footer.scss'
import callIcon from '../assets/images/call-icon.png'
import mailIcon from '../assets/images/mail-icon.png'
import pinIcon from '../assets/images/pin-icon.png'
import Whatsapp from '../assets/images/Whatsapp'
import Instagram from '../assets/images/Instagram'
import Facebook from '../assets/images/Facebook'
import Youtube from '../assets/images/Youtube'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='container' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div>
          <h3>CONTACT US</h3>
          <ul className='info-list'>
            <li>
              <img src={callIcon} />
              <a href='tel:6353577808'>{'6353577808'}</a>
            </li>
            <li>
              <img src={pinIcon} />
              <a href='https://maps.app.goo.gl/AT3feaxdohNN1Lre7' target='_blank'>
                office no. c1-307, celebration city center,
                <br />
                gala gym khana road, south bopal,
                <br />
                ahmedabad - 380 058.
              </a>
            </li>
            <li style={{ alignItems: 'center' }}>
              <img src={mailIcon} />
              <a href='mailto:mukund.marvellproperties7@gmail.com'>mukund.marvellproperties7@gmail.com</a>
            </li>
          </ul>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
          <div style={{ marginBottom: '20px' }}>
            <span style={{ marginRight: '15px' }}>
              <a href='https://api.whatsapp.com/send?phone=6353577808' target='_blank'>
                <Whatsapp />
              </a>
            </span>
            <span style={{ marginRight: '15px' }}>
              <a target='_blank' href='https://www.instagram.com/marvellproperties/'>
                <Instagram />
              </a>
            </span>
            <span style={{ marginRight: '15px' }}>
              <a target='_blank' href='https://www.facebook.com/marvellproperties?mibextid=2JQ9oc'>
                <Facebook />
              </a>
            </span>
            <span style={{ marginRight: '15px' }}>
              <a target='_blank' href='https://youtube.com/@MARVELLPROPERTIES'>
                <Youtube />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
