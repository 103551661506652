import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../assets/sass/views/About.scss'
import Slider from 'react-slick'

const About = () => {
  const aboutImage = [
    '',
    'https://kavisha.rededesignstudio.com/wp-content/uploads/2021/02/c3-3d-gallery-thumb-original5.jpg',
    'https://kavisha.rededesignstudio.com/wp-content/uploads/2021/02/c3-3d-gallery-thumb-original4.jpg'
  ]

  return (
    <>
      <Header isActive='AboutUs' />
      <div className='about-us-sec'>
        <div className='container'>
          <div className='text-center'>
            <h2 className='common-title red'>About us</h2>
          </div>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <p style={{ textAlign: 'justify' }}>
                &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Investing in a property is a very important financial
                and life goal for most individuals. We understand all the worries and hassle twirled around taking such a decision and here
                we can help you to decide with our expertise. When it comes to investing your hard earned money, we will ensure you get the
                best out of your investment,
              </p>
            </div>
            <div className='offset-md-1 col-md-5'>
              <img
                className='feature-slide'
                src='https://kavisha.rededesignstudio.com/wp-content/uploads/2021/02/c3-3d-gallery-thumb-original3.jpg'
              ></img>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default About
