import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import bodyLogo from '../../assets/images/body_logo.jpg'
import Mukund from '../../assets/images/Mukeund.png'
import '../../assets/sass/views/Home.scss'
import 'bootstrap/dist/css/bootstrap.css'
import { Button, Col, Row, Spinner } from 'reactstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { getTable, submitData } from '../../query/contact-us/contact.mutation'
import { toast } from 'react-toastify'

const Home = () => {
  const navigate = useNavigate()
  const buyRentSell = ['Buy', 'Sell', 'Rent']
  const location = [
    '100 Feet Road',
    'Adalaj',
    'Ambawadi',
    'Ambli',
    'Ashram Road',
    'Bavla',
    'Bhat',
    'Bhuyangdev',
    'Bodakdev',
    'Bopal',
    'CG Road',
    'chanakyapuri',
    'Chandkheda',
    'Chandlodia',
    'Changodar',
    'Dholera',
    'Drive In Road',
    'Ellisbridge',
    'Gandhinagar',
    'Ghatlodia',
    'Ghuma',
    'Gota',
    'Gulbai Tekra',
    'Gurukul',
    'Hebatpur Road',
    'Income Tax',
    'Iscon Ambli Road',
    'Jagatpur',
    'Jivrajpark',
    'Jodhpur',
    'Juhapura',
    'Koba',
    'Koteshwar',
    'Kudasan',
    'Law Garden',
    'Makarba',
    'Manekbaug',
    'Manipur',
    'Memnagar',
    'Mithakhali',
    'Motera',
    'Naranpura',
    'Navrangpura',
    'Nehru Nagar',
    'New CG Road',
    'Area',
    'New Ranip',
    'New Wadaj',
    'Nirnay Nagar',
    'Other Area',
    'Paldi',
    'Palodia',
    'Prahladnagar',
    'Ramdevnagar',
    'Rancharda',
    'Randheja',
    'Ranip',
    'Raysan',
    'Sabarmati',
    'Sanand',
    'Sanathal',
    'Santej',
    'Sargasan',
    'Sarkhej',
    'Satadhar',
    'satellite',
    'Science City',
    'SG Road',
    'Shahibaug',
    'Shastrinagar',
    'Shela',
    'shilaj',
    'Shivranjani',
    'shyamal',
    'Sindhubhavan Road',
    'Sola',
    'South Bopal',
    'SP Ring Road',
    'Subhash Bridge',
    'Surdhara Circle',
    'Thaltej',
    'Thol',
    'Tragad',
    'usmanpura',
    'Vaishno Devi',
    'vasna',
    'vastrapur',
    'vavol',
    'vejalpur',
    'Vijay Cross Road',
    'wadaj',
    'Zundal'
  ]

  const propertyType = [
    'Residential Apartment',
    'Residential Independent House / Villa',
    'Residential Independent / Builder Floor',
    'Residential Studio Apartment',
    'Residential Farm House',
    'Guest house/ banquet hall',
    'Residential Row House',
    'Residential Twin Bungalow',
    'Residential Twin Apartment',
    'Residential Duplex',
    'Residential Terrace',
    'Residential Penthouse',
    'Residential Tenement',
    'Residential Bungalow',
    'Residential Triplex',
    'Residential basement',
    'Commercial Serviced Apartment',
    'Commercial Shop',
    'Commercial Showroom',
    'Commercial Office/Space',
    'Commercial Time share',
    'Commercial Space in Retail Mall',
    'Commercial Office in Business Park',
    'Commercial Office in IT Park',
    'Commercial Business centre',
    'Commercial Financial Institution',
    'Corporate House',
    'Commercial Institutes',
    'Commercial Labor Camp',
    'Commercial Chemical Zone',
    'Commercial Restaurant',
    'Commercial Flat',
    'Commercial Terrace Restaurant',
    'Commercial Education Institutes',
    'Commercial Built to Suit',
    'Home Stay',
    'Commercial Multiplex',
    'Commercial basement',
    'Commercial bungalow',
    'Co-Working Office Spaces',
    'Commercial Shop Cum Office Spaces(SCO)',
    'Commercial Shop Cum Flat(SCF)',
    'Commercial Booth',
    'Commercial Bay Shop',
    'Commercial Building',
    'PG',
    'Commercial Hotel/ Resort',
    'Industrial Cold storage',
    'Industrial Factory',
    'Industrial Manufacturing',
    'Warehouse/Godown',
    'Industrial Building',
    'Industrial Gala',
    'Residential Land/ Plot',
    'Commercial Land/ Plot',
    'Industrial Land/ Plot',
    'Agricultural Farm/Land',
    'Transfer of Development Rights (TDR)    ',
    'Party Plot    ',
    'Amenity Land'
  ]

  const budget = [
    '5 Lac',
    '10 Lac',
    '20 Lac',
    '30 Lac',
    '40 Lac',
    '50 Lac',
    '60 Lac',
    '70 Lac',
    '80 Lac',
    '1 Cr',
    '1.2 Cr',
    '1.4 Cr',
    '1.6 Cr',
    '1.8 Cr',
    '2 Cr',
    '2.3 Cr',
    '2.6 Cr',
    '2.9 Cr',
    '3 Cr',
    '3.5 Cr',
    '4 Cr',
    '4.5 Cr',
    '5 Cr',
    '10 Cr',
    '20 Cr',
    '50 Cr'
  ]

  const bhk = ['1 Room', '1 Room & Kitchen', '1BHK', '2 Room', '2BHK', '3BHK', '4BHK', '5BHK', '6BHK', 'Above 6BHK']

  const transaction = [
    'Transaction',
    'New',
    'Resale',
    'Pre Launch',
    'Pre Lease/ Pre Rented',
    'Individual',
    'Company',
    'Distress Sale',
    'Group Booking',
    'Individual / Company'
  ]

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    mobile: '',
    location: '',
    buyorrent: '',
    propertyType: '',
    roomBHK: '',
    transaction: '',
    minBudget: '',
    maxBudget: '',
    squareFeet: ''
  })

  const [isMobileValid, setIsMobileValid] = useState(true)

  const handleChange = (e) => {
    if (e.target.name === 'mobile') {
      if (/^\d{10}$/.test(e.target.value)) {
        setIsMobileValid(true)
      } else {
        setIsMobileValid(false)
      }
    }
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const { isLoading: submittingLoading, mutate } = useMutation(submitData, {
    onSuccess: (data) => {
      toast.success('Data submitted successfully')
    },
    onError: (data) => {
      toast.error('Something went wrong, please try again later')
    }
  })
  const convertToNumeric = (value) => {
    if (value.endsWith('Lac')) {
      return parseFloat(value.replace(' Lac', '')) / 100
    } else if (value.endsWith('Cr')) {
      return parseFloat(value.replace(' Cr', ''))
    }
    return parseFloat(value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()

    // Convert budget values to numeric format (in Crores for 'Cr' values)
    const minBudgetValue = convertToNumeric(formData.minBudget)
    const maxBudgetValue = convertToNumeric(formData.maxBudget)

    // Validate minimum and maximum budgets
    if (!isNaN(minBudgetValue) && !isNaN(maxBudgetValue) && minBudgetValue >= maxBudgetValue) {
      toast.error('Minimum budget must be less than maximum budget')
      return
    }

    mutate({ formData })
    setFormData({
      username: '',
      email: '',
      mobile: '',
      location: '',
      buyorrent: '',
      propertyType: '',
      roomBHK: '',
      transaction: '',
      minBudget: '',
      maxBudget: '',
      squareFeet: ''
    })
  }

  return (
    <>
      <Header isActive='home' />
      <>
        <div className='home-banner-first'>
          <div className='container_1'>
            <h3 style={{ color: 'black' }}>Hello, </h3>
            <h2 style={{ color: 'black' }}>I am Mukund Vekariya</h2>
            <h5 style={{ color: 'black', marginTop: '15px' }}>Property Consultant | Managing Director</h5>
          </div>
          <div className='container_2'>
            <img src={Mukund} />
          </div>
        </div>
        <div className='home_banner'>
          <div id='carouselExampleControls' class='carousel slide' data-ride='carousel'>
            <div class='carousel-inner'>
              <div class='carousel-item active'>
                <img
                  class='d-block w-100'
                  src='https://images.pexels.com/photos/7534269/pexels-photo-7534269.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                  alt='First slide'
                  minHeight='95vh'
                />
                <div className='carousel-caption '>
                  <h1>
                    <b>VERIFIED HOMES FOR YOU</b>
                  </h1>
                  <h4>We evaluate, verify and list property</h4>
                  <Button onClick={() => navigate('/contact')}>More Details</Button>
                </div>
              </div>
              <div class='carousel-item'>
                <img class='d-block w-100' src='https://i.ibb.co/N78zwxW/canvas-banner-1.jpg' alt='First slide' minHeight='95vh' />
                <div class='carousel-caption carousel-caption '>
                  <h1>
                    <b>UPGRADE TO BIGGER HOME</b>
                  </h1>
                  <h4>Looking to fulfil your dream, upgrade to bigger home</h4>
                  <Button onClick={() => navigate('/contact')}>More Details</Button>
                </div>
              </div>
              <div class='carousel-item'>
                <img
                  class='d-block w-100'
                  src='https://i.ibb.co/CHKVsxV/resize-16920433891986342480banner.jpg'
                  alt='First slide'
                  minHeight='95vh'
                />
                <div class='carousel-caption carousel-caption '>
                  <h1>
                    <b>SWEET HOME FOR SMALL FAMILY</b>
                  </h1>
                  <h4>Book your first sweet home with top amenities and enjoy your dream.</h4>
                  <Button onClick={() => navigate('/contact')}>More Details</Button>
                </div>
              </div>
            </div>
            <a class='carousel-control-prev' href='#carouselExampleControls' role='button' data-slide='prev'>
              <span class='carousel-control-prev-icon' aria-hidden='true'></span>
              <span class='sr-only'>Previous</span>
            </a>
            <a class='carousel-control-next' href='#carouselExampleControls' role='button' data-slide='next'>
              <span class='carousel-control-next-icon' aria-hidden='true'></span>
              <span class='sr-only'>Next</span>
            </a>
          </div>
        </div>
      </>
      <div className='home_contact_us_page'>
        <h2 style={{ textAlign: 'center' }}>Contact Us</h2>
        <form style={{ marginTop: '30px' }} onSubmit={handleSubmit} className='container'>
          <Row>
            <Col>
              <div className='form-group'>
                <label htmlFor='username'>Name:</label>
                <input
                  type='text'
                  className='form-control'
                  id='username'
                  name='username'
                  value={formData.username}
                  onChange={handleChange}
                  required
                  placeholder='Enter your name'
                />
              </div>
            </Col>
            <Col>
              <div className='form-group'>
                <label htmlFor='email'>Email:</label>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  required
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  placeholder='Enter your email id'
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className='form-group'>
                <label htmlFor='mobile'>Mobile Number:</label>
                {/* <input
                  type='tel'
                  className='form-control'
                  id='mobile'
                  name='mobile'
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  placeholder='Enter your mobile number'
                /> */}

                <input
                  type='tel'
                  className={`form-control ${isMobileValid ? '' : 'is-invalid'}`}
                  id='mobile'
                  name='mobile'
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  placeholder='Enter your mobile number'
                />
                {!isMobileValid && <div className='invalid-feedback'>Invalid mobile number. Please enter up to 10 digits.</div>}
              </div>
            </Col>
            <Col>
              <div className='form-group'>
                <label htmlFor='buyorrent'>For Buy/Rent:</label>
                <select
                  className='form-control'
                  id='buyorrent'
                  name='buyorrent'
                  value={formData.buyorrent}
                  onChange={handleChange}
                  required
                >
                  <option value='' disabled>
                    Select Property Type
                  </option>
                  {buyRentSell.map((buyorrent) => (
                    <option key={buyorrent} value={buyorrent}>
                      {buyorrent}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className='form-group'>
                <label htmlFor='location'>Location:</label>
                <select className='form-control' id='location' name='location' value={formData.location} onChange={handleChange} required>
                  <option value='' disabled>
                    Select Location
                  </option>
                  {location.map((location) => (
                    <option key={location} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col>
              <div className='form-group'>
                <label htmlFor='propertyType'>Property Type:</label>
                <select
                  className='form-control'
                  id='propertyType'
                  name='propertyType'
                  value={formData.propertyType}
                  onChange={handleChange}
                  required
                >
                  <option value='' disabled>
                    Select Property Type
                  </option>
                  {propertyType.map((propertyType) => (
                    <option key={propertyType} value={propertyType}>
                      {propertyType}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className='form-group'>
                <label htmlFor='minBudget'>Minimum Budget:</label>
                <select className='form-control' id='minBudget' name='minBudget' value={formData.minBudget} onChange={handleChange}>
                  <option value='' disabled>
                    Select Minimum Budget
                  </option>
                  {budget.map((minBudget) => (
                    <option key={minBudget} value={minBudget}>
                      {minBudget}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col>
              <div className='form-group'>
                <label htmlFor='maxBudget'>Maximum Budget:</label>
                <select className='form-control' id='maxBudget' name='maxBudget' value={formData.maxBudget} onChange={handleChange}>
                  <option value='' disabled>
                    Select Maximum Budget
                  </option>
                  {budget.map((maxBudget) => (
                    <option key={maxBudget} value={maxBudget}>
                      {maxBudget}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className='form-group'>
                <label htmlFor='roomBHK'>BHK:</label>
                <select className='form-control' id='roomBHK' name='roomBHK' value={formData.roomBHK} onChange={handleChange}>
                  <option value='' disabled>
                    Select BHK
                  </option>
                  {bhk.map((roomBHK) => (
                    <option key={roomBHK} value={roomBHK}>
                      {roomBHK}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col>
              <div className='form-group'>
                <label htmlFor='squareFeet'>Square Feet:</label>
                <input
                  type='number'
                  className='form-control'
                  id='squareFeet'
                  name='squareFeet'
                  value={formData.squareFeet}
                  onChange={handleChange}
                  placeholder='Enter square feet'
                  min='0' // Minimum allowed value
                  max='999999' // Maximum allowed value
                  onInput={(e) => {
                    const value = e.target.value
                    if (value < 0) {
                      e.target.value = 0
                    } else if (value > 999999) {
                      e.target.value = 999999
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                      e.preventDefault()
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='form-group'>
                <label htmlFor='transaction'>Transaction:</label>
                <select className='form-control' id='transaction' name='transaction' value={formData.transaction} onChange={handleChange}>
                  <option value='' disabled>
                    Select Transaction
                  </option>
                  {transaction.map((transaction) => (
                    <option key={transaction} value={transaction}>
                      {transaction}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col></Col>
          </Row>

          <button type='submit' className='btn btn-primary' disabled={submittingLoading}>
            Submit
            {submittingLoading && <Spinner size='sm' className='ms-2' />}
          </button>
        </form>
      </div>
      <Footer />
    </>
  )
}
export default Home
