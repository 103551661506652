import React from 'react'

function OuterProjectCard({ data, index, handleIndex }) {
  return (
    <>
      <div className='card' style={{ width: '400px' }}>
        <img src={data?.cardImage} alt='Property Image' width='400px' height='300px' />
        <div className='card-body'>
          <h5 className='card-title'>
            <b>{data?.propertyTitle}</b>
          </h5>
          <p className='card-text'>{data?.propertyLocation}</p>
          <p className='card-text' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <san>{data?.propertySize}</san>
            <san>{data?.propertyPriceRooms}</san>
          </p>

          <button onClick={() => handleIndex(index)} className='btn btn-primary'>
            More Detail
          </button>
        </div>
      </div>
    </>
  )
}

export default OuterProjectCard
